import { DriscollsRichText, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { Container, TextField } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import AnnouncementCreateFooter from 'components/announcement/AnnouncementCreateFooter';
import AnnouncementRecipients from 'components/announcement/AnnouncementRecipients';
import AnnouncementValidation from 'components/announcement/AnnouncementValidation';
import { FOLDER_NAMES } from 'constants/foldernames';
import ApplicationContext from 'context/ApplicationContext';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useDeleteAttachment from 'hooks/useDeleteAttachment';
import useGetAllRanches from 'hooks/useGetAllRanches';
import useGetSignedUrl from 'hooks/useGetSignedUrl';
import useMapSuperAdminData from 'hooks/useMapSuperAdminData';
import useSendAnnouncement from 'hooks/useSendAnnouncement';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import { convertDateToIso } from '../../utils/helper';

const CreateAnnouncement = (props) => {
    useCheckMasterData();
    useMapSuperAdminData();
    const [richText, setRichText] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedRanch, setSelectedRanch] = useState(null);
    const [recipients, setRecipients] = useState([]);
    const [expirationDate, setExpirationDate] = useState(null);
    const [announcementHeader, setAnnouncementHeader] = useState('');
    const [fileDetails, setFileDetails] = useState([]);
    const currentFile = useRef();
    const [attachments, setAttachments] = useState([]);
    const navigate = useNavigate();
    const [formTouched, setFormTouched] = useState(false);
    const { sendAnnouncementHandler, loading } = useSendAnnouncement(props.oktaAuth);
    const { fetchSignedUrl, loading: fetchFileLoading } = useGetSignedUrl(props.oktaAuth);
    const { deleteAttachment, errorDeleteAttachment, loading: deleteAttachmentLoading } = useDeleteAttachment(props.oktaAuth);
    const ranches = useGetAllRanches();
    const { ranches: companies, companies: districts, setShowToast } = useContext(ApplicationContext);

    const initAssignment = {
        districts,
        companies: [],
        ranches: []
    };
    const [assignments, setAssignments] = useState(initAssignment);

    const { getTextTranslated } = useLang();

    useEffect(() => {
        if (!formTouched && (announcementHeader || recipients.length > 0 || expirationDate || richText)) {
            setFormTouched(true);
        }
    }, [announcementHeader, recipients, expirationDate, richText, formTouched]);

    const sendAnnouncement = async () => {
        const companiesArray = recipients.map((itm) => itm?.company?.value).filter((value) => !!value);
        const ranchesArray = recipients.map((itm) => itm?.ranch?.value).filter((value) => !!value);
        const sendAnnouncementPayload = {
            Districts: recipients.map((itm) => itm.district.value).join(', '),
            Companies: companiesArray.length > 0 ? companiesArray.join(', ') : null,
            Ranches: ranchesArray.length > 0 ? ranchesArray.join(', ') : null,
            RecipientType: 'GROWER',
            TextHeader: announcementHeader,
            TextBody: richText,
            AttachementLink: attachments.length > 0 ? attachments.map((itm) => itm).join(',') : '',
            FileType: fileDetails.length > 0 ? fileDetails[0].fileFormat : '',
            FileName: fileDetails.length > 0 ? fileDetails[0].fileName : '',
            FileKey: fileDetails.length > 0 ? fileDetails[0].attachmentId : '',
            ExpirationDate: convertDateToIso(expirationDate)
        };
        sendAnnouncementHandler(sendAnnouncementPayload);
    };

    function filterAssignments(array1, array2, key1, key2) {
        const keySet = new Set(array2.map((item) => item[key2]));
        return array1.reduce((acc, item) => {
            if (keySet.has(item[key1])) {
                acc.push(item);
            }
            return acc;
        }, []);
    }

    const deleteAttachmentHandler = (attachmentId) => {
        deleteAttachment(attachmentId);
        if (!errorDeleteAttachment) setFileDetails(fileDetails.filter((file) => file.attachmentId !== attachmentId));
    };

    const handleChange = (event, name) => {
        if (event) {
            switch (name) {
                case 'District': {
                    setSelectedDistrict(event);
                    setAssignments({ ...assignments, companies: filterAssignments(companies, event.Companies, 'value', 'Code') });
                    break;
                }
                case 'Company': {
                    setSelectedCompany(event);
                    setAssignments({ ...assignments, ranches: filterAssignments(ranches, event.Ranches, 'value', 'Code') });
                    break;
                }
                case 'Ranch': {
                    setSelectedRanch(event);
                    break;
                }
                default:
                    setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
            }
        }
    };

    const handleDelete = (districtCode) => {
        setRecipients([...recipients.filter((itm) => itm.district.value !== districtCode)]);
    };

    const completeUpload = (uploadId) => {
        //This completes the upload process
        setAttachments([...attachments, uploadId + '.' + currentFile.current.fileFormat]);
        currentFile.current = { ...currentFile.current, attachmentId: uploadId };
        setFileDetails([...fileDetails, { ...currentFile.current, attachmentId: uploadId }]);
    };

    const handleCapture = ({ target }) => {
        const fileReader = new FileReader();
        const file = target.files[0];

        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            console.log(e.target.result);
            const fileFormat = file.name.split('.').pop();
            currentFile.current = { fileName: file.name, fileFormat };
            fetchSignedUrl(file, file.type, file.name, FOLDER_NAMES.announcement, completeUpload);
        };
    };

    const formIsValid = () => {
        return announcementHeader && recipients.length > 0 && expirationDate && richText;
    };

    const addRecipients = () => {
        setRecipients([...recipients, { district: selectedDistrict, ranch: selectedRanch, company: selectedCompany }]);
    };

    const resetAssignments = () => {
        setAssignments(initAssignment);
        setSelectedDistrict(null);
        setSelectedCompany(null);
        setSelectedRanch(null);
    };

    const goBackHandler = () => {
        navigate(routes[routesName.ANNOUNCEMENT].getRoute());
    };

    return (
        <DrcContainer heading={getTextTranslated('Create Announcement', NAMESPACE.ANNOUNCEMENT)} goBackHandler={goBackHandler}>
            <Container>
                <TextField
                    label={getTextTranslated('Announcement Header', NAMESPACE.ANNOUNCEMENT)}
                    onChange={(e) => setAnnouncementHeader(e.target.value)}
                    value={announcementHeader}
                    sx={{ mb: 2 }}
                    fullWidth
                />

                <AnnouncementRecipients
                    selectedDistrict={selectedDistrict}
                    selectedCompany={selectedCompany}
                    selectedRanch={selectedRanch}
                    assignments={assignments}
                    recipients={recipients}
                    addRecipients={addRecipients}
                    resetAssignment={resetAssignments}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                />

                <DriscollsRichText label={`${getTextTranslated('Start typing', NAMESPACE.COMMON)}...`} getJsonValue={(value) => setRichText(value)} />

                <AnnouncementCreateFooter
                    expirationDate={expirationDate}
                    setExpirationDate={setExpirationDate}
                    fileDetails={fileDetails}
                    deleteAttachment={deleteAttachmentHandler}
                    deleteAttachmentLoading={deleteAttachmentLoading}
                    handleCapture={handleCapture}
                    fetchFileLoading={fetchFileLoading}
                    sendAnnouncement={sendAnnouncement}
                    loading={loading}
                    disabledSendAnnouncement={formIsValid()}
                />

                {formTouched && !formIsValid() && (
                    <AnnouncementValidation
                        announcementHeader={announcementHeader}
                        expirationDate={expirationDate}
                        recipients={recipients.length === 0}
                        richText={richText}
                    />
                )}
            </Container>
        </DrcContainer>
    );
};

export default withOktaAuth(CreateAnnouncement);
