import { ButtonVariant, DriscollsAddButton } from '@driscollsinc/style-guide-web2.0';
import { Box, Chip } from '@mui/material';
import FormAutoComplete from 'components/shared/formcontrols/FormAutoComplete';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { Fragment } from 'react';
import classes from '../../pages/announcement/announcement.module.scss';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';

const AnnouncementRecipients = ({
    recipients,
    selectedDistrict,
    selectedCompany,
    selectedRanch,
    handleDelete,
    addRecipients,
    handleChange,
    assignments,
    resetAssignment
}) => {
    const { getTextTranslated } = useLang();

    return (
        <Fragment>
            <Box className={classes.recipientContainer}>
                <Box className={classes.fieldsContainer}>
                    <TypographyTranslated nameSpacing={NAMESPACE.ANNOUNCEMENT}>{'Recipients'}</TypographyTranslated> :{' '}
                    {recipients.map((itm, i) => (
                        <Chip
                            key={i}
                            label={`District: ${itm.district.label}, Company: ${itm?.company?.label ?? ''}, Ranch: ${itm?.ranch?.label ?? ''}`}
                            onDelete={() => handleDelete(itm.district.value)}
                        />
                    ))}
                </Box>
            </Box>
            <Box className={classes.assignmentContainer}>
                <FormAutoComplete
                    labelField="label"
                    className={classes.assignmentSelect}
                    value={selectedDistrict}
                    values={assignments.districts}
                    onChangeHandler={(e: React.ChangeEvent) => handleChange(e, 'District')}
                    label={getTextTranslated('Districts', NAMESPACE.ANNOUNCEMENT)}
                />
                <FormAutoComplete
                    labelField="label"
                    className={classes.assignmentSelect}
                    values={assignments.companies}
                    value={selectedCompany}
                    onChangeHandler={(e: React.ChangeEvent) => handleChange(e, 'Company')}
                    label={getTextTranslated('Companies', NAMESPACE.ANNOUNCEMENT)}
                />
                <FormAutoComplete
                    labelField="label"
                    className={classes.assignmentSelect}
                    values={assignments.ranches}
                    value={selectedRanch}
                    onChangeHandler={(e: React.ChangeEvent) => handleChange(e, 'Ranch')}
                    label={getTextTranslated('Ranches', NAMESPACE.ANNOUNCEMENT)}
                />
                <DriscollsAddButton
                    className={classes.addButton}
                    onClick={() => {
                        addRecipients();
                        resetAssignment();
                    }}
                    variant={ButtonVariant.SECONDARY}
                    disabled={!selectedDistrict}
                    label="Add"
                />
            </Box>
        </Fragment>
    );
};

export default AnnouncementRecipients;
