import { PERSONAID } from 'data/config/persona';
import { useContext, useEffect } from 'react';
import { cloneArray } from 'utils/helper';
import ApplicationContext from '../context/ApplicationContext';

const useMapSuperAdminData = () => {
    const { userPersona, companies, ranches, districtOptions, setRanches, setCompanies, setDistrictOptions } = useContext(ApplicationContext);
    const { SUPERADMIN, PORTALUSER } = PERSONAID;
    useEffect(() => {
        if (
            [SUPERADMIN, PORTALUSER].includes(userPersona) &&
            companies &&
            districtOptions &&
            ranches &&
            companies.length !== districtOptions.length
        ) {
            const companyAndRanches = [];
            const districtAndCompanies = [];
            // directly mapping was mutating an original array so created clones for each to map super admin master data
            const clonedCompanies = cloneArray(companies);
            const clonedRanches = cloneArray(ranches);
            const clonedDistricts = cloneArray(districtOptions);
            clonedCompanies.map((company) => {
                company.Ranches = clonedRanches.filter((ranch) => parseInt(company.CompanyNbr) === parseInt(ranch.GrowerNbr));
                companyAndRanches.push(company);
            });
            setRanches(companyAndRanches);
            clonedDistricts.map((district) => {
                district.Companies = clonedCompanies.filter(
                    (company) =>
                        company.DistrictCode?.trim().toUpperCase() === district.GrowingDistrictCode?.trim().toUpperCase() ||
                        company.DistrictCode?.trim().toUpperCase() === district.GrowingDistrictName?.trim().toUpperCase()
                );
                districtAndCompanies.push(district);
            });
            setCompanies(districtAndCompanies);
        }
        setDistrictOptions(
            districtOptions.map((item) => {
                return { ...item, label: item.DistrictName + ' - ' + item.DistrictCode };
            })
        );
    }, []);
};

export default useMapSuperAdminData;
